<template>
    <div class="carousel-cell" v-if="shouldRenderVariation">
        <div class="stacked stack-image" v-if="loading === false">
            <img :src="currentImage" v-if="articles.images.length > 0" class="img-slider cursor-pointer"
                alt="slide image" v-on:click="openProductDetail"/>
        </div>
        <div class="stacked stack-image" v-else>
            <el-skeleton-item variant="image" class="shimmer-img"/>
        </div>
        <div class="carousel-content">
            <p class="font-bold text-md text-base carousel-text cursor-pointer" v-on:click="openProductDetail"
                v-if="loading === false">
                {{ articles.description }}
            </p>
            <p class="carousel-cell-price font-semibold text-blue" v-if="loading === false">
                {{ this.getPrice() }} {{ articles.currency }}
            </p>
            <p v-else>
                <el-skeleton-item variant="p" class="product-desc-slider"/>
                <el-skeleton-item variant="p" class="product-desc-slider"/>
            </p>
        </div>
    </div>
</template>

<style scoped>
.carousel-container {
    display: flex;               /* Use Flexbox for horizontal alignment */
    flex-wrap: nowrap;           /* Prevent items from wrapping to the next line */
    justify-content: space-between; /* Distribute items evenly */
    overflow-x: auto;            /* Enable horizontal scrolling if necessary */
    width: 100%;                 /* Ensure the container takes up the full width */
}

.carousel-cell {
    flex: 0 0 auto;              /* Prevent items from shrinking or growing */
    width: 200px;                /* Fixed width for each item */
    margin-right: 10px;          /* Add some space between items */
    box-sizing: border-box;      /* Ensure padding and border are included in width calculation */
    display: flex;               /* Use Flexbox inside each item for vertical alignment */
    flex-direction: column;      /* Stack content vertically */
    background-color: #f9f9f9;   /* Background color */
    padding: 9px;                /* Padding inside each cell */
    border: 1px solid #ddd;      /* Border to distinguish items */
    align-items: center;         /* Center content horizontally */
    justify-content: center;     /* Center content vertically */
}

.carousel-text {
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    word-break: break-word;
    max-width: 100%;             /* Ensure text does not overflow the item */
}

.carousel-cell img {
    display: block;
    max-height: 100%;
    object-fit: cover;           /* Ensure the image covers the defined area */
}

.shimmer-img {
    height: 87px;
    margin: auto;
    display: table;
    width: 100px;
}

.img-slider {
    margin: auto;
    display: table;
    height: 100px;
    width: 100px;
}

.product-desc-slider {
    width: 70%;
    height: 10px;
    margin: auto;
    display: table;
    margin-bottom: 5px;
}

.text-blue {
    color: #1e40af !important;
}


</style>





  <script>
  import moment from "moment";
  
  export default {
	name: "variations",
	props: {
	  product_id: {
		type: Number,
		required: true
	  },
	  user_type: {
		type: String,
		default: 'buyer'
	  }
	},
	data: function () {
	  return {
		articles: {
		  images: []
		},
		loading: true,
		image: {
		  image: ''
		},
		mainArticle: null
	  }
	},
	computed: {
	  currentImage() {
		return this.articles.images.length > 0 ? this.articles.images[0].image : '';
	  },
	  shouldRenderVariation() {
		return this.mainArticle && this.mainArticle.article_variations.includes(this.product_id);
	  }
	},
	methods: {
	  getArticle: function () {
		this.loading = true
		this.$store.dispatch('customer/getGenericSingleArticles', {pageUrl: `/${this.product_id}/?company_id=${this.$route.params.company_id}`})
		  .then(resp => {
			this.loading = false
			this.articles = resp.data
			if (this.articles.images.length > 0) {
			  this.image = this.articles.images[0]
			}
		  }).catch((err) => {
		  this.loading = false
		  if (err.response && err.response.status === 401) {
			this.$store.commit('auth/CLEAR_AUTH_CUS', null)
			window.Bus.$emit('sign-out')
		  }
		})
	  },
	  getSellerArticle: function () {
		this.loading = true
		this.$store.dispatch('portal/getSingleArticle', {url: `/${this.product_id}/`})
		  .then(resp => {
			this.loading = false
			this.articles = resp.data
			if (this.articles.images.length > 0) {
			  this.image = this.articles.images[0]
			}
		  }).catch((err) => {
		  this.loading = false
		  if (err.response && err.response.status === 401) {
			this.$store.commit('auth/CLEAR_AUTH_CUS', null)
			window.Bus.$emit('sign-out')
		  }
		})
	  },
	  getPrice: function () {
		let currentDate = moment().format('YYYY-MM-DD')
		if (this.articles.scheduled_campaign_start_date && this.articles.scheduled_campaign_end_date) {
		  let startDate = moment(this.articles.scheduled_campaign_start_date).format('YYYY-MM-DD')
		  let endDate = moment(this.articles.scheduled_campaign_end_date).format('YYYY-MM-DD')
		  if (currentDate >= startDate && currentDate <= endDate) {
			return this.articles.discounted_price
		  }
		}
		return this.articles.sales_price
	  },
	  openProductDetail() {
		window.Bus.$emit('set-current-variation', this.articles)
	  },
	  getMainArticle() {
		const mainProductId = this.$route.params.product_id;
		this.$store.dispatch('customer/getGenericSingleArticles', {pageUrl: `/${mainProductId}/?company_id=${this.$route.params.company_id}`})
		  .then(resp => {
			this.mainArticle = resp.data;
		  }).catch((err) => {
			console.error('Error fetching main article:', err);
		  });
	  }
	},
	mounted() {
	  this.getMainArticle();
	  let self = this
	  setTimeout(function () {
		if (self.user_type === 'buyer') {
		  self.getArticle()
		} else {
		  self.getSellerArticle()
		}
	  }, 500)
	}
  }
 
  </script>

